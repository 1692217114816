#facility_banner, #rates_banner {
	max-width: 1440px;
	width: 100%;
	margin: 0 auto;
	padding: 1em;
	background: $secondary;
	color: #fff;
	text-align: center;
	font-family: $font;
	font-size: 24px;
	border-radius: 10px;
	box-sizing: border-box;

	p {
		font-size: 1.25em;
		font-weight: 700;

		&+p {
			font-size: 1em;
			font-weight: 400;
		}
	}
}

#rates_banner {
	margin-top: 50px;
}

#about {
	max-width: 1845px;
	width: 90%;
	margin: 15em auto 2em;
	border: 20px solid #f9f4ee;
	border-radius: 30px;
	position: relative;
}

#about_info {
	background-color: $primary;
	border-radius: 10px;
	max-width: 1350px;
	margin: -200px auto 0;
	padding: 4em;
	color: #fff;
	line-height: 2.5;
	font-size: 1.1rem;
	z-index: 2;
}

#features_info {
	font-family: $font;
	text-align: center;
	color: $primary;
	padding: 1.5em 0;

	h2 {
		font-size: 2.5rem;
		padding: 1.5em;
	}
}

#feature_items {
	display: flex;
	flex-wrap: wrap;
	max-width: 900px;
	margin: 0 auto;
}

.f_item {
	padding: 0 50px;
	line-height: 1;
	margin-bottom: 75px;
	flex: 1 0 33.33%;
	box-sizing: border-box;

	img {
		margin-bottom: 30px;
		max-width: 50px;
	}

	strong {
		font-weight: 600;
	}

	&:not(:last-child):not(:nth-child(3)) {
		border-right: 1px solid #cfdcd4;
	}
}

.electric_size_descriptor {
	display: block;
	color: #000;
	font-style: initial;
	font-size: 16px;
	font-weight: 700;
}

/***>>>Media Queries***/
@media screen and (max-width: 1500px) {

	#facility_banner, #rates_banner {
		width: 95%;
	}
}

@media screen and (max-width: 950px) {

	#about {
		border: none;
		width: 100%;
	}

	#feature_items {

		.f_item {
			border: none;
		}
	}

	#about_info {
		padding: 1.5em;
		line-height: 2;
	}

	.f_item {
		margin-bottom: 40px;
	}
}

@media screen and (max-width: 750px) {

	#facility_banner, #rates_banner {
		margin: 20px auto 0;
		font-size: 20px;
	}
}

@media screen and (max-width: 650px) {

	.f_item {

		flex: 1 0 50%;

		p {
			max-width: 150px;
			width: 100%;
			margin: 0 auto;
		}
	}
}

@media screen and (max-width: 520px) {

	#about_info {
		padding: 2em;
		font-size: 1rem;
	}
}

@media screen and (max-width: 500px) {

	.f_item {
		padding: 0 12px;
	}
}

@media screen and (max-width: 450px) {

	#facility_banner, #rates_banner {
		font-size: 16px;
	}
}