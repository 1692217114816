#locations_wrapper {
	display: flex;
	justify-content: center;
	max-width: 1920px;
	width: 100%;
	padding: 35px 0;
	margin: 0 auto;
	min-height: 350px;
	font-family: $font;
}

#locations_listing {
	width: 47%;
	flex-shrink: 0;
	margin-right: 20px;

	h2 {
		color: $primary;
		font-size: 3rem;
		padding-bottom: 25px;
		border-bottom: 2px solid $accent;
	}

	.location_item {
		display: flex;
		padding: 25px 5px 25px 0;
		align-items: center;
		justify-content: space-between;

		& + .location_item {
			border-top: 2px solid $accent;
		}

		img {
			padding-right: 10px;
		}

		.btn {
			padding: 20px 80px;
			font-size: 1.2rem;
			white-space: nowrap;

			&.coming_soon_btn {
				padding: 15px 80px;
			}
		}
	}

	.location_num {
		font-size: 36px;
		font-weight: 700;
		display: flex;
		width: 55px;
		height: 55px;
		color: $primary;
		align-items: center;
		justify-content: center;
	}

	.details {
		display: flex;
		width: 235px;
		flex-direction: column;
		align-items: flex-start;

		h3 {
			font-size: 20px;
			font-weight: 400;
			color: $primary;

			a {
				color: $primary;
				transition: color .3s;

				&:hover {
					color: $primary;
					text-decoration: none;
				}
			}
		}

		a {
			font-weight: 700;

			display: block;

			text-decoration: none;

			color: #000;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.location_address {
		line-height: 1.6;
		color: $primary;
		font-size: 20px;
		margin: 0 0 10px;
	}

	.empty_space {
		width: 45px;
	}

	.empty_btn_space {
		width: 254px;
	}

	.location_phone {
		display: flex;
		align-items: center;

		img {
			margin: 0 6px 0 0;
		}
	}
}

#locations_map {
	margin-right: 100px;
	margin-left: 20px;
	border-radius: 20px;
	height: 630px;
	max-width: 925px;
	flex-grow: 1;

	.map_pin {
		position: relative;
		display: block;
		transition: transform .3s;
		transform-origin: bottom center;

		&:hover, &.hover {
			z-index: 1;
			transform: scale(1.3);
		}

		span {
			font-size: 12px;
			font-weight: 700;
			position: absolute;
			top: 7px;
			left: 50%;
			display: flex;
			transform: translateX(-50%);
			color: $primary;
			align-items: center;
			justify-content: center;
		}
	}
}

#search_map_locations {
	margin: 75px 0;
}

@media screen and (max-width: 1400px) {

	#locations_listing {

		.location_item {

			.btn {
				padding: 20px 50px;

				&.coming_soon_btn {
					padding: 15px 50px;
				}
			}
		}
	}
}

@media screen and (max-width: 1200px) {

	#locations_listing {
		width: 500px;

		.empty_btn_space {
			width: 143px;
		}
	}

	#locations_map {
		margin: 0 35px 0 20px;
	}
}

@media screen and (max-width: 1000px) {

	#locations_listing {
		width: 400px;

		.location_num {
			font-size: 30px;

			width: 35px;
			height: 35px;
			margin: 0 20px 0 0;
		}

		.location_item {

			.btn {
				padding: 15px 30px 15px 20px;

				&.coming_soon_btn {
					padding: 10px 25px;
				}
			}
		}

		.details {

			h3 {
				font-size: 22px;
			}
		}

		.location_address {
			font-size: 18px;
		}
	}
}

@media screen and (max-width: 750px) {

	#locations_wrapper {
		flex-direction: column-reverse;
		padding: 35px 0 0;
	}

	#locations_listing {
		width: 100%;
		padding: 5px;

		h2 {
			font-size: 2.2rem;
			padding-bottom: 20px;
			padding-right: 10px;
		}

		.location_num {
			margin: 0 10px 0 0;
		}
	}

	#locations_map {
		width: 100%;
		height: 450px;
		margin: 0 0 35px;
	}
}

@media screen and (max-width: 500px) {

	#locations_listing {

		.location_item {
			padding: 10px 5px;

			img {
				width: 30px;
			}
		}

		.details {

			h3, .location_address {
				font-size: 15px;
			}
		}
	}
}