main {
	display: block;
	margin: 0 auto;
	padding: 0;
	font-family: $font;
	width: 100%;
	height: 100%;
	min-width: 320px;
	max-width: 1920px;
	position: relative;
}

#internal_header_wrapper {
	min-height: 330px;
	width: 100%;
}

#internal_header {
	padding: 30px 0 0 0;
	margin-bottom: 50px;
	background-image: linear-gradient(0deg, $accent, #ffffff 90%);

	.internal_title {
		background: $primary;
		border-top-right-radius: 15% 80%;
		font-size: 3rem;
		text-align: center;
		font-weight: 700;
		color: #fff;
		display: inline-block;
		padding: 0 90px 0 80px;
	}
}

#internal_content_wrapper {
	display: flex;
	flex-direction: column;
	max-width: 1024px;
	background-color: #fff;
	margin-left: auto;
	margin-right: auto;
	margin-top: -330px;
	width: 100%;
	padding: 30px;
	box-sizing: border-box;
	position: relative;
}

#internal_content {
	background-image: linear-gradient(180deg, $accent, #ffffff 120px);
	padding: 30px;

	&.about {

		p {
			color: $primary;
			font-size: 20px;
			margin-bottom: 35px;
		}

		img {
			display: block;
			margin: 0 auto 35px;
			text-align: center;
			max-width: 600px;
			width: 100%;
		}
	}

	&.error {

		font-size: 18px;

		a {
			color: #000;
			text-decoration: underline;

			&:hover {
				text-decoration: none;
				color: #1b5733;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#internal_header {

		.internal_title {
			font-size: 38px;
			padding: 0 40px 0 20px;
			border-top-right-radius: 20% 70%;
		}
	}

	#internal_content_wrapper {
		padding: 20px;
	}
}

@media screen and (max-width: 500px) {

	#internal_header {

		.internal_title {
			font-size: 32px;
			padding: 2px 40px 0 20px;
		}
	}

	#internal_content_wrapper {
		padding: 10px;
	}
}