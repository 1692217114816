html {
	scroll-behavior: smooth;
}

#home {
	padding: 0;
	width: 100%;
	min-width: 320px;
	max-width: 1888px;
	background-image: none;

	/*stylelint-disable selector-max-id */

	#locations_wrapper {
		padding: 95px 0;
	}
	/*stylelint-enable selector-max-id */
}

.caro-wrapper {
	position: relative;
}

#home-carou {

	background-image: url('/dist/images/gallery/home_hero.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 13px;
	height: 750px;
	width: 100%;
	z-index: -1;

	.caro_dot {
		display: block;
		margin: 8px 6px;
		background-color: transparent;
		border: 1px solid #fff;
		box-shadow: none;
		width: 15px;
		height: 15px;

		&.active {
			background-color: #fff;
			border: 1px solid #fff;
		}
	}

	.dots_container {
		top: 240px;
		left: 30px;
		width: 30px;
		height: fit-content;
		z-index: 1;
	}
}

.carousel {
	position: relative;

	.caro_left {
		width: 0;
	}

	.caro_inner {
		margin: 3px 0;
		font-size: 2.8rem;
		border-radius: 10px;
	}


	.caro_slide {
		box-sizing: border-box;
		right: 0px;
		display: none;
		width: 100%;
		margin-top: -250px;
		top: 33%;
		position: absolute;
		height: 46.875rem;

		img {
			height: 46.875rem;
		}

		hr {
			width: 74%;
			position: absolute;
			top: 20px;
			left: 33px;
			border: 1px solid white;
		}
	}
}

.carou-content-wrapper {
	width: 100%;
	margin: 0 auto;
	display: block;
	position: absolute;
	box-sizing: border-box;
	top: 20px;

	.carou-content-container {
		display: flex;
		justify-content: center;
		text-align: center;

		.carou-text {
			font-size: 1.9rem;
			color: white;
			margin: 141px 15px 10px 15px;

			h2 {
				color: white;
				font-size: 3rem;
				display: inline;
				max-width: 480px;
			}

			h1 {
				font-size: 3rem;

				span {
					border-bottom: 2px solid white;
				}
			}

			.moving-text {

				font-weight: 700;
				overflow: hidden;
				font-size: 40px;
				display: inline-block;
				max-height: 70px;
				width: 296px;
			}
		}
	}
}

.home-hero-locs-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 20px;
}

.btn_container {
	display: flex;
	align-items: center;
	justify-content: center;
	align-content: center;
	flex-wrap: wrap;
	position: relative;
	width: 250px;
	min-height: 85px;
	margin: 0 .5em .5em;
	padding: 0;
	background-color: #ef4136;
	color: white;
	line-height: 1;
	font-size: 1.8rem;
	text-decoration: none;
	text-shadow: #4c4949 1px 1px 4px;
	border: solid 2px white;

	span {
		font-size: .75em;
		width: 100%;
		padding: 0;
		margin: 0;
	}

	&.rates {
		padding: 3px 20px;
		width: 52%;

		.rates-a {
			padding: 8px;

			a {
				font-size: 1.6rem;
			}
		}
	}

	&:after {
		display: block;
		content: "";
		position: absolute;
		color: #fff;
		top: 50%;
		right: 13px;
		height: 19px;
		width: 19px;
	}

	hr {
		margin: 6px 0px 9px 8px;
		width: 94%;
		border: solid #ffae8657 2px;
	}
}

.home-features-wrapper {
	position: relative;
	height: 212px;
	width: 100%;
	margin-top: -212px;
	background-color: rgba(0, 0, 0, .6);
	border-radius: 13px;
	display: grid;
	z-index: 998;
	grid-template-columns: repeat(6, 1fr);
	align-items: stretch;

	.home-feature-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		border-right: solid 1px #4d4842;
		line-height: 1.1;

		&.last {
			border-right: none;
		}

		img {
			margin-bottom: 20px;
		}

		p {
			color: white;
			text-align: center;
			font-size: 1rem;
		}
	}
}

//Description Section with image on right

.mid-content {
	position: relative;

	.home-about-img {
		position: absolute;
		right: -65px;
		top: -105px;
		border-radius: 20px;
		max-width: 58%;
	}

	.mid-content-wrapper {
		width: 100%;
		background-color: #f9f4ee;
		padding: 60px 0px 1px 0px;
		margin-top: 100px;
		border-radius: 50px;
		position: relative;

		.mid-content-container {
			background-color: white;
			margin: 20px 20px 80px 20px;
			border-radius: 25px;
			position: relative;

			.mid-content-text {
				padding: 30px 100px;
				width: 31%;
				font-size: 1.2rem;
				line-height: 2;

				h1 {
					font-size: 2.2rem;
					color: $primary;
					font-weight: 700;
				}

				p {
					color: $primary;
					line-height: 2.7;
				}
			}

			//Locations with map

			.locs {
				display: flex;
				margin: 27px 40px 20px 40px;
				padding: 5px 5px 90px 5px;

				.loc-map {
					width: 45%;
					height: 63vh;
					border: thin solid lightgray;
					border-radius: 15px;
				}

				.loc-right-container {
					display: flex;
					flex-direction: column;
					width: 55%;

					.loc-links-wrapper {
						display: grid;
						margin: 40px;
						align-items: center;
						grid-template-columns: 44% 36% 4% 5% 4%;
						grid-column-gap: 1em;

						h2 {
							font-size: 2.2rem;
							color: $primary;
							font-weight: 700;
						}

						h3 {
							font-size: 1.8rem;
							color: $primary;
						}

						a {
							padding-right: 30px;
						}
					}

					.loc-info {
						display: grid;
						margin: 9px 35px;
						align-items: center;
						/* justify-content: center; */
						grid-template-columns: 10% 41% 12% 61%;


						h1 {
							font-size: 4rem;
							color: $primary;
							font-weight: 700;
						}

						h2 {
							font-size: 2.2rem;
							color: $primary;
							font-weight: 700;
						}

						p {
							font-size: 1.3rem;
							color: $primary;
						}

						.rates-wrapper {
							display: flex;
							align-items: center;
						}
					}

					hr:not(:last-child) {
						width: 90%;
						border: solid 1px #f9f4ee;
					}

					hr:last-child {
						display: none;
					}

					.rates-divider {
						margin: 6px 0px 7px 34px;
						width: 93%;
						border: solid #f6f2e9 1px;
					}
				}
			}
		}
	}
}

/***>>Media Queries***/

@media screen and (max-width:1600px) {

	.mid-content {

		.home-about-img {
			position: relative;
			right: 0;
			top: 0;
			max-width: 650px;
			width: 100%;
			height: 425px;
			align-self: center;
		}

		.mid-content-wrapper {

			.mid-content-container {
				display: flex;
			}
		}
	}
}

@media screen and (max-width:1500px) {

	.home-features-wrapper {

		.home-feature-container {
			padding: 20px 11px;
		}
	}
}

@media screen and (max-width:1350px) {

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				padding-bottom: 60px;
				flex-direction: column;

				.mid-content-text {

					width: 85%;
				}
			}
		}
	}
}

@media screen and (max-width:1250px) {

	#home-carou {
		height: 650px;
	}

	.home-features-wrapper {

		.home-feature-container {
			padding: 20px 8px;
		}
	}

	.carou-content-wrapper {

		.carou-content-container {

			.carou-text {
				margin: 0;
			}
		}
	}

	#home {

		.btn {
			max-width: 305px;
			font-size: 1.8rem;
		}
	}

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				.locs {
					flex-direction: column;
					padding: 0 5px 90px 5px;
					margin: 0 40px 20px 40px;

					.loc-map {
						width: 100%;
						height: 52vh;
					}

					.loc-right-container {

						hr:not(:last-child) {
							width: 163%;
							margin: 0px 68px;
						}

						.loc-links-wrapper {
							grid-template-columns: 99% 67% 6% 8% 6%;
						}

						.loc-info {
							margin: 1px 63px;
							align-items: center;
							grid-template-columns: 15% 93% 34% 112%;
						}
					}
				}

				.mid-content-text {
					width: 80%;
				}
			}
		}
	}
}

@media screen and (max-width:1100px) {

	.home-features-wrapper {
		max-width: 100%;
	}

	#home {

		.btn {
			max-width: 255px;
			padding: 15px 0 18px 0;
			font-size: 1.6rem;

			hr {
				margin: 0 0 9px 6px;
			}

			a {
				padding: 0px 38px 0px 10px;
			}

			&.rates {
				width: 85%;
			}
		}
	}


	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				.locs {
					flex-direction: column;
					padding: 0 5px 90px 5px;
					margin: 0 40px 20px 40px;

					.loc-right-container {

						.loc-info {
							margin: 1px 63px;
							grid-template-columns: 21% 92% 27% 95%;
						}

						hr:not(:last-child) {
							width: 165%;
							margin: 0px 70px;
						}

						.loc-links-wrapper {
							grid-template-columns: 98% 70% 6% 8% 6%;
						}
					}
				}
			}
		}
	}
}

@media screen and (max-width:1000px) {

	.carou-content-wrapper {

		.carou-content-container {

			.carou-text {

				.moving-text {
					margin: 0 auto;
					display: block;
				}

				h1 {
					font-size: 32px;
				}

				h2 {
					font-size: 32px;
				}
			}
		}
	}

	#home {

		.btn {
			max-width: 206px;
			padding: 16px 8px 12px 6px;
			margin: 18px 6px;

			a {
				padding: 0px 16px 0px 0px;
				font-size: 1.5rem;
			}
		}
	}

	.mid-content {

		.mid-content-wrapper {

			.mid-content-container {

				.locs {

					.loc-right-container {

						hr:not(:last-child) {
							width: 169%;
							margin: 0px 62px;
						}

						.loc-links-wrapper {

							h2 {
								font-size: 1.8rem;
							}

							h3 {
								font-size: 1.5rem;
							}
						}

						.loc-info {
							grid-template-columns: 23% 88% 24% 96%;
							margin: 1px 49px;

							a {
								padding-right: 10px;
							}

							h2 {
								font-size: 1.8rem;
							}
						}
					}

					.loc-map {
						height: 40vh;
					}
				}

				.mid-content-text {
					margin: 0 auto;
					padding: 30px 94px;
				}
			}
		}
	}

	.btn_container {
		margin: .5em;
		min-height: 70px;
		font-size: 24px;
	}

	.home-features-wrapper {
		height: 180px;
		margin-top: -180px;
	}
}

@media screen and (max-width:750px) {

	.caro-wrapper {
		height: 1050px;
		background: url(/dist/images/bg/woodbackground.jpg) no-repeat;
		background-size: cover;
		border-radius: 10px;
	}

	.home-features-wrapper {
		position: relative;
		top: auto;
		grid-template-columns: repeat(2, 1fr);
		margin-top: -345px;
		width: 100%;
		max-width: 100%;
		height: auto;

		.home-feature-container {
			padding: 10px 20px;
			border-right: none;

			p {
				font-size: 12px;
			}
		}
	}

	.carou-content-wrapper {

		.carou-content-container {

			.carou-text {
				margin: 40px 2px 10px 2px;
				color: $primary;

				h1 {
					font-size: 30px;
				}

				h2 {
					color: $primary;
					font-size: 30px;
				}
			}
		}
	}

	.mid-content {

		.mid-content-wrapper {
			margin-top: 25px;

			.mid-content-container {

				padding-top: 35px;
				padding-bottom: 35px;

				.mid-content-text {
					padding: 0 36px;
					width: 87%;

					h1 {
						font-size: 1.8rem;
					}
				}

				.locs {
					margin: 0;
					padding: 0 5px 6px 5px;

					.loc-right-container {
						width: 64%;

						hr:not(:last-child) {
							width: 138%;
							margin: 0px 32px;
						}

						.loc-links-wrapper {
							grid-template-columns: 131% 7% 11% 8%;

							h3 {
								display: none;
							}
						}

						.loc-info {
							margin: 1px 26px;
							grid-template-columns: 17% 75% 0% 100%;

							a {
								padding: 0;

								img {
									display: none;
								}
							}

							h1 {
								font-size: 2.6rem;
							}

							h2 {
								font-size: 1.6rem;
							}

							p {
								font-size: 1rem;
							}
						}
					}
				}
			}
		}

		.home-about-img {
			display: none;
		}
	}

	#home-carou {
		display: none;
	}

	.home-hero-locs-wrapper {
		flex-direction: column;
		align-items: center;
	}

	#home {

		.btn {

			&::after {
				top: 56%;
				height: 13px;
				width: 13px;
			}

			&.rates {
				width: 59%;

				a {
					padding: 4px;
				}
			}
		}
	}
}

@media screen and (max-width:500px) {

	.carou-content-wrapper {

		.carou-content-container {

			.carou-text {
				max-width: 100%;
				margin: 0;
				color: $primary;

				h1 {
					font-size: 2rem;
					margin-top: 20px;
				}
			}
		}
	}

	.carousel .caro_inner {
		font-size: 2.1rem;
	}

	.caro-wrapper {
		height: 820px;
	}

	.home-hero-locs-wrapper {
		padding: 0;
	}

	#home {

		.btn:not(:last-child) {
			margin: 5px;
		}

		.btn {
			margin: 4px 0;

			&.rates {
				width: 80%;

				.rates-a {
					padding: 4px;
				}
			}
		}
	}


	.home-features-wrapper {
		margin-top: -315px;

		.home-feature-container {
			padding: 5px;
		}
	}

	.mid-content {

		.mid-content-wrapper {
			margin-top: 15px;
			padding: 31px 0 8px 0;

			.mid-content-container {
				margin: 23px 20px 20px 20px;

				.locs {

					.loc-right-container {

						margin: 0 auto;
						width: 100%;

						hr:not(:last-child) {
							width: 83%;
							margin: 12px 28px;
						}

						.loc-links-wrapper {
							display: flex;
							flex-direction: column;
							margin: 25px;

							a {
								display: none;
							}
						}

						.loc-info-wrapper {
							width: 100%;
							margin: 0 auto;
						}

						.loc-info {
							width: 100%;
							grid-template-columns: 9% 55%;
							justify-content: center;
							margin: 0 auto;
							text-align: center;

							.rates-wrapper {
								grid-column: 1/3;
								justify-content: center;
							}
						}
					}
				}

				.mid-content-text {
					width: 86%;
					line-height: 1.3;
					padding-top: 10px;

					p {
						line-height: 1.5;
					}
				}
			}
		}
	}
}