#m_health_banner {
	display: flex;
	align-items: center;
	color: #ffffff;
	background-color: #00637c;
	font-family: 'Rokkitt', sans-serif;
	font-size: 21pt;
	max-width: 1400px;
	width: 100%;
	padding: 8px 35px;
	box-sizing: border-box;
	position: fixed;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 999;

	p {
		padding: 20px;
		text-align: center;
	}

	a {
		color: #3fa9f5;
		font-weight: 700;
		text-transform: uppercase;
		text-decoration: underline;
		white-space: nowrap;
	}
}

#m_health_close_btn {
	cursor: pointer;
	position: absolute;
	top: 15px;
	right: 15px;
	width: 30px;
	height: 30px;
	border-radius: 500px;
	background-color: #ffffff;
	transition: background-color .3s;
	border: none;

	&:hover {
		background-color: #9b9b9b;
	}

	&:before, &:after {
		position: absolute;
		display: block;
		content: '';
		top: 5px;
		left: 50%;
		width: 2px;
		height: 20px;
		background: #00637c;
		z-index: 15;
	}

	&:before {
		transform: translateX(-50%) rotate(45deg);
	}

	&:after {
		transform: translateX(-50%) rotate(-45deg);
	}
}

#footer_top {
	border-top: 14px solid $primary;
	padding-top: 35px;
	font-family: $font;

	ul {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	li {

		& +li {
			margin-left: 40px;
		}
	}

	a {
		position: relative;
		color: $primary;
		font-size: 20px;
		text-decoration: none;

		&::after {
			position: absolute;
			content: "";
			left: 0;
			bottom: 0;
			width: 100%;
			height: 1px;
			background: $primary;
			transform: scaleX(0);
			transform-origin: center;
			transition: transform .5s;
		}

		&:hover {

			&::after {
				transform: scaleX(1);
			}
		}
	}
}

#footer_bottom {
	padding: 30px 0;
	font-family: $font;

	ul {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	li {

		& +li {
			margin-left: 20px;
		}

		img {
			max-height: 35px;
		}
	}
}

#footer_credit {
	display: block;
	max-width: 150px;
	margin: 30px auto 0;
	color: $primary;
	text-align: center;
	text-decoration: none;
	font-size: 12px;

	img {
		display: block;
		margin: 0 auto;
	}
}
/***>>Media Queries***/
@media screen and (max-width: 1100px) {

	#footer_top {

		li {

			& +li {
				margin-left: 25px;
			}
		}
	}
}

@media screen and (max-width: 950px) {

	#m_health_banner {

		p {
			font-size: 15pt;
			font-weight: normal;
		}

		.lock_ic {
			display: none;
		}
	}

	#m_health_close_btn {
		top: 10px;
		right: 10px;
	}

	#footer_top {

		li {

			& +li {
				margin-left: 10px;
			}
		}

		a {
			font-size: 18px;
		}
	}
}

@media screen and (max-width: 750px) {

	#footer_top {

		ul {
			flex-wrap: wrap;
			line-height: 1.8;
			padding: 0 15px;
		}
	}

	#footer_bottom {
		padding: 20px 0;

		ul {
			flex-wrap: wrap;
		}

		li {
			display: flex;
			justify-content: center;
			padding: 0 0 10px;
		}

		.footer_social_link {
			width: 45%;
			justify-content: flex-end;

			& +.footer_social_link {
				justify-content: flex-start;
				margin-left: 20px;
			}
		}
	}

	#footer_credit {
		margin: 20px auto 0;
	}
}

@media screen and (max-width: 550px) {

	#m_health_banner {

		img {
			display: none;
		}

		p {
			text-align: left;
		}
	}
}