/* stylelint-disable

    font-family-no-duplicate-names,
    font-family-no-missing-generic-family-keyword,
    block-no-empty,
    comment-no-empty,
    no-duplicate-selectors,
    declaration-no-important,
    declaration-property-value-blacklist,
    declaration-block-single-line-max-declarations,
    selector-max-id,
    selector-max-universal
*//**** Legacy ****/

#SLWE.internal {

	#content {
		padding: 35px;
	}

	.ProgramH1 {
		font-family: 'Oswald', sans-serif;
		font-size: 36px;
		font-weight: 700;

		margin-bottom: 10px;

		color: #ef4236;
	}

	.ProgramH2 {
		font-family: 'Oswald', sans-serif;
		font-size: 24px;
		font-weight: 600;

		color: #414042;
	}

	a {
		color: #ef4236;
	}

	td {
		vertical-align: middle;
	}

	input,textarea,select {
		font-size: 16px;
	}

	textarea {
		width: 98%;
	}

	strong {
		font-weight: 600;
	}

	table td ul li {
		margin: 0 0 0 25px !important;
	}
}

/***>>>Media Queries***/

#SLWE.internal {
	@media screen and (max-width: 800px) {

		.ProgramH1 {
			font-size: 28px;
		}

		.ProgramH2 {
			font-size: 22px;
		}

		table {

			table td {
				vertical-align: top;

				table tr > td {
					vertical-align: middle;
				}
			}

			td table tr > td {
				vertical-align: middle;
			}
		}
	}
}

@media screen and (max-width: 750px) {

	#SLWE.internal #content {
		padding: 20px 10px !important;
	}
}
