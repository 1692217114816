#access_privacy {

	h2 {
		color: #000;
		font-size: 38px;
		margin-top: 30px;
	}

	h3 {
		color: #000;
		font-size: 28px;
		font-weight: 600;
		margin-top: 30px;
		margin-bottom: 5px;
	}

	p {
		color: #000;
		line-height: 1.4em;
		margin-bottom: 20px;
		font-size: 18px;
	}

	ul {
		line-height: 1.4em;
		list-style: disc;
		padding-left: 18px;
		font-size: 18px;

		li {
			color: #000;
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.ul_style {
		list-style: disc;
		margin: 0 0 30px 20px;
	}

	a {
		color: #000;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
			color: $primary;
		}
	}
}

/***>>Media Queries***/

@media screen and (max-width:380px) {

	#access_privacy a {
		word-break: break-all;
	}
}